<template>
  <div class="login-page">
    <div class="login-page__content-wrapper">
      <div class="login-page__content">
        <div class="login-page__top">
          <div class="logo">
            <router-link to="/">
              <img
                src="/logo.svg"
                alt="svg"
              >
            </router-link>
          </div>
        </div>
        <div class="login-page__form">
          <PhonePage
            v-if="!isSend"
            ref="phoneForm"
            @submit="submitPhone"
          />
          <AuthCodePage
            v-else
            ref="codeForm"
            :disabled="isConfirm"
            @submit="submitCode"
            @resend="resendCode"
          />
          <div
            v-if="code"
            class="login-page__code"
          >
            {{ code }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("auth");

import PhonePage from "./phone/phone";
import AuthCodePage from "./code/code";

export default {
  name: "Auth",
  components: {
    PhonePage,
    AuthCodePage,
  },
  data() {
    return {
      isSend: false,
      isConfirm: false,
      phone: "",
      code: "",
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {
    if (this.isLoggedIn) {
      this.next();
    }

    this.user().then(() => {
      this.next();
    }).catch(()=>{
      console.info("Unauthenticated");
    });
  },
  methods: {
    ...mapActions(["login", "confirm", "resend", "user"]),
    submitPhone() {
      this.isConfirm = false;
      this.login(this.$refs.phoneForm.form.phone)
        .then((res) => {
          this.isSend = true;
          this.code = res.code;
          this.$infra.tracker.step1submitTell()
        })
        .catch((errors) => {
          if (errors.phone) {
            this.$refs.phoneForm.formErrors.phone = errors.phone[0];
          }
        });
    },
    submitCode() {
      this.isConfirm = true;
      this.confirm(this.$refs.codeForm.form.code)
        .then(() => {
          this.next();
          this.$infra.tracker.step2submitCode()
        })
        .catch((errors) => {
          this.isConfirm = false;

          if (errors.code) {
            this.$refs.codeForm.formErrors.code = errors.code[0];
          }
        });
    },
    resendCode() {
      this.isConfirm = false;
      this.resend().then(() => {
        this.isSend = true;
      });
    },
    next() {
      this.$router.push({ name: "lkcontracts" });
    },
  },
};
</script>

<style lang="scss" src="./login-page.scss" />
